import styled from 'styled-components';
const Fieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
 
`;

const TableStyle = styled.div`
  .ant-table-column-title{
    z-index:0;
  }
`;




const Label = styled.label`
  font-size: 13px;
  color: black;
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  margin: 0 0 8px;
`;
export {
    Fieldset,
    Label,
    TableStyle
  };
  