import React, { Component } from "react";
import { connect } from "react-redux";
import { Fieldset } from "../Users/users.style";
import {
  toggleModalService,
  update,
  createService,
  deleteService,
  getServices,
} from "../../redux/services/servicesState";
import Box from "../../components/utility/box";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import { Table, Button, Breadcrumb, Space, Modal, Form, Input } from "antd";
import Header from "../../components/utility/header";
import ComponentTitle from "../../components/utility/componentTitle";
import {
  DashboardOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import axios from "../../api";
import clone from "clone";
import { notification } from "antd";
import { getLoggedInUser } from "../../redux/auth/authState";

export const openNotificationWithIcons = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchedColumn: "",
      deleteModalVisible: false,
      serviceToDelete: null,
    };
    this.searchInput = React.createRef();
  }
  async componentDidMount() {
    try {
      await this.props.getServices();
      await axios.get("usermanagement/services").then((response) => {
        this.setState({ services: response.data });
      });
    } catch (error) {
      console.error(error);
    }
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleRecord = (actionName, service) => {
    try {
      if (service.key && actionName !== "delete") actionName = "update";
      this.props.createService({ service, actionName });
    } catch (error) {
      console.error(error);
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleModal = (service = null) => {
    this.props.toggleModalService(service);
  };

  showDeleteModal = (service) => {
    this.setState({ deleteModalVisible: true, serviceToDelete: service });
  };

  hideDeleteModal = () => {
    this.setState({ deleteModalVisible: false, serviceToDelete: null });
  };

  handleDelete = () => {
    this.props.createService({
      service: this.state.serviceToDelete,
      actionName: "delete",
    });
    this.hideDeleteModal();
  };

  onRecordChange = (key, event) => {
    let { service } = clone(this.props);
    if (key) service[key] = event.target.value;
    this.props.update(service);
  };

  render() {
    const { service, services, loggedInUser } = this.props;
    const dataSource = [];

    Object.keys(services).map((service, index) => {
      return dataSource.push({
        ...services[service],
        key: service,
      });
    });

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 20,
      },
    };

    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };

    const columns = [
      {
        title: "Services",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
    ];
    const hasEditPermission =
      loggedInUser?.permissions?.includes("editService");
    const hasDeletePermission =
      loggedInUser?.permissions?.includes("deleteService");

    // Conditionally add the "Action" column based on permissions
    if (hasEditPermission || hasDeletePermission) {
      columns.push({
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            {hasEditPermission && (
              <a onClick={this.handleModal.bind(this, record)} href="#">
                <EditOutlined />
              </a>
            )}
            {hasDeletePermission && (
              <a onClick={() => this.showDeleteModal(record)} href="#">
                <DeleteOutlined style={{ color: "red" }} />
              </a>
            )}
          </Space>
        ),
      });
    }

    return (
      <LayoutContentWrapper>
        <Breadcrumb
          items={[
            {
              href: "/ethiomaps",
              title: <DashboardOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <UserOutlined />
                  <span>Service Management</span>
                </>
              ),
            },
            {
              title: "Services",
            },
          ]}
        />
        <Box title="" subtitle="" style={{ marginTop: "3.5%" }}>
          <Header>
            <ComponentTitle>Services</ComponentTitle>
            {loggedInUser?.permissions?.includes("createService") ? (
              <Button
                className="addButton"
                onClick={this.handleModal.bind(this, null)}
              >
                Add Service
              </Button>
            ) : null}
          </Header>
          <Modal
            title="Confirm Delete"
            open={this.state.deleteModalVisible}
            onOk={this.handleDelete}
            onCancel={this.hideDeleteModal}
            okText="Yes"
            cancelText="No"
          >
            Are you sure you want to delete this service?
          </Modal>

          <Modal
            open={this.props.modalservice}
            title={
              this.props.service.key ? "Update Service" : "Add New service"
            }
            okText={this.props.service.key ? "Update Service" : "Add Service"}
            onOk={this.handleRecord.bind(this, "insert", service)}
            onCancel={this.handleModal.bind(this, null)}
          >
            <Form
              {...layout}
              name="nest-messages"
              // onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                label="Service Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter service name!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="name"
                    placeholder="Enter Service Name"
                    value={service.name}
                    onChange={this.onRecordChange.bind(this, "name")}
                  />
                </Fieldset>
              </Form.Item>
            </Form>
          </Modal>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
            }}
            scroll={{ x: "max-content" }}
          />
        </Box>
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.service,
    loggedInUser: state.auth.loggedInUser,
  };
}

const mapDispatchToProps = {
  getServices,
  deleteService,
  toggleModalService,
  update,
  createService,
  getLoggedInUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
