import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
    name: 'role',
    initialState:{
        isLoading: false,
        modalrole: false,
        errorMessage: false,
        role: {
            key:null,
        },
        roles: {}
    },
    reducers:{
        toggleModalRole: (state, action) => {
            state.modalrole =  !state.modalrole;
            state.role= action.payload ? (action.payload):({});
        },
        update: (state, action) => {
            state.role = action.payload
        },
        getRoles: (state, action) => {
            state.isLoading = true;
            state.errorMessage = false;
            state.modalrole = false;
        },
        getRolesSuccess: (state, action) => {
            state.isLoading = false;
            state.errorMessage = false;
            state.roles=action.payload;
        },
        getRolesError: (state, action) => {
            state.isLoading = false;
            state.errorMessage = 'There is a Loading problem';
        },
        createRole: (state, action) => {
            
        },
    }
});

export const {toggleModalRole, update,createRole,getRoles,getRolesError,getRolesSuccess} = roleSlice.actions;
export default roleSlice.reducer;
