import React, { useEffect, useState } from "react";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import DashboardWidgetWrapper from "./widgets-wrapper";
import StickerWidget from "./sticker-widget";
import { Row, Col } from "antd";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { WidgetBox } from "./style";
import { Avatar, Divider, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../../api";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const dataList = [
  {
    picture: { large: "" },
    name: { last: "John Doe" },
    email: "john.doe@example.com",
  },
  {
    picture: { large: "" },
    name: { last: "Jane Smith" },
    email: "jane.smith@example.com",
  },
  {
    picture: { large: "" },
    name: { last: "John Doe" },
    email: "john.doe@example.com",
  },
  {
    picture: { large: "" },
    name: { last: "Jane Smith" },
    email: "jane.smith@example.com",
  },
  {
    picture: { large: "" },
    name: { last: "John Doe" },
    email: "john.doe@example.com",
  },
  {
    picture: { large: "" },
    name: { last: "Jane Smith" },
    email: "jane.smith@example.com",
  },
];

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("usermanagement/dashboard");
        const data = Object.entries(response.data).map(([label, value]) => ({
          label,
          value,
        }));
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {}, [dashboardData]);

  const renderStickerWidgets = () => {
    return dashboardData.map((item, index) => (
      <Col
        lg={8}
        md={12}
        sm={24}
        xs={24}
        style={{ marginBottom: "2%" }}
        key={index}
      >
        <DashboardWidgetWrapper>
          <StickerWidget
            number={item.value}
            text={
              item.label === "clientCount"
                ? "Total Clients"
                : item.label === "serviceCount"
                ? "Total Services"
                : item.label === "userCount"
                ? "Total Users"
                : item.label
            }
            icon="ion-email-unread"
            fontColor="#ffffff"
            bgColor="#86adb3"
          />
        </DashboardWidgetWrapper>
      </Col>
    ));
  };

  const wisgetPageStyle = {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    overflow: "hidden",
  };

  return (
    <LayoutContentWrapper>
      <div style={wisgetPageStyle}>
        <Row gutter={0} style={{ width: "100%" }}>
          {/* Render sticker widgets */}
          {renderStickerWidgets()}
        </Row>
        {/* Area Chart */}
        {/* <Row gutter={0} style={{width:'100%'}}>
          <Col lg={14} md={24} sm={24} xs={24} style={{marginBottom:'2%'}} >
            <DashboardWidgetWrapper>
              <WidgetBox>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#86adb3" />
                  </AreaChart>
                </ResponsiveContainer>
              </WidgetBox>
            </DashboardWidgetWrapper>
          </Col>
          {/* Infinite Scroll List */}
        {/* <Col lg={10} md={24} sm={24} xs={24} style={{marginBottom:'2%'}} >
            <DashboardWidgetWrapper>
              <WidgetBox>
                <div
                  id="scrollableDiv"
                  style={{
                    height: '107%',
                    width: '107%',
                    overflow: 'auto',
                  }}
                >
                  <InfiniteScroll
                    dataLength={data.length}
                    next={this.loadMoreData}
                    hasMore={false}
                    loader={
                      <Skeleton
                        avatar
                        paragraph={{
                          rows: 1,
                        }}
                        active
                      />
                    }
                    endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={dataList}
                      renderItem={(item) => (
                        <List.Item key={item.email}>
                          <List.Item.Meta
                            avatar={<Avatar src={item.picture.large} />}
                            title={<a href="https://ant.design">{item.name.last}</a>}
                            description={item.email}
                          />
                          <div>Content</div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </WidgetBox>
            </DashboardWidgetWrapper>
          </Col>
        </Row> */}
      </div>
    </LayoutContentWrapper>
  );
};

export default Dashboard;
