import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Signin from "./containers/Page/signin";
import ChangePassword from "./containers/Page/changepassword";
import App from "./containers/App/App";
import Users from "./containers/Users/users";
import Roles from "./containers/Roles/roles";
import Permissions from "./containers/Permissions/permission";
import Zclients from "./containers/client/client";
import Services from "./containers/services/zservices";
import Dashboard from "./containers/Dashboard/Dashboard";
import Places from "./containers/Places/places";
import Place_detail from "./containers/Places/place_detail";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
/*<Route path="clients" element={<Zclients />} /> */
const RestrictedRoute = ({ isAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate("/signin", { replace: true, state: { from: location } });
    return null; // Return null to avoid rendering the `Outlet`
  }

  return <Outlet />;
};
const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Router basename="/admin">
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route element={<RestrictedRoute isAuthenticated={isLoggedIn} />}>
          <Route path="ethiomaps" element={<App />}>
            <Route path="users" element={<Users />} />
            <Route path="roles" element={<Roles />} />
            <Route path="permissions" element={<Permissions />} />
            <Route path="clients" element={<Zclients />} />
            <Route path="services" element={<Services />} />
            <Route path="places" element={<Places />} />
            <Route path="place_detail" element={<Place_detail />} />
            <Route path="" element={<Dashboard />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.idToken !== null ? true : false,
  };
}

export default connect(mapStateToProps)(PublicRoutes);
