import React, { Component } from "react";
import { Header } from "antd/es/layout/layout";
import { TopbarWrapper } from "./topbar.style";
import { MenuOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { toggleCollapsed } from "../../redux/app/appState";
import UserDropdown from "./userDropdown";
import user from "../../image/user4.png";
import edit from "../../image/edit.png";
import logout from "../../image/log-out.png";

class Topbar extends Component {
  render() {
    const userImage = user;
    const textArray = [
      { img: edit, text: "Edit Profile" },
      { img: logout, text: "Logout" },
    ];
    const styling = {
      background: "#F7F8FC",
      width: "100%",
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            this.props.collapsed
              ? "isomorphicTopbar collapsed"
              : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                this.props.collapsed
                  ? "triggerBtn menuCollapsed -ml-10"
                  : "triggerBtn menuOpen -ml-10"
              }
              onClick={this.props.toggleCollapsed}
            >
              <MenuOutlined />
            </button>
            <div
              className={` ${this.props.collapsed ? "" : " hidden md:block"}`}
            >
              <ul>
                <li className="isoNotify"></li>
                <li className="isoMsg"></li>
                <UserDropdown userImg={userImage} text={textArray} />
              </ul>
            </div>
          </div>
        </Header>
      </TopbarWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    collapsed: state.app.collapsed,
  };
}

const mapDispatchToProps = {
  toggleCollapsed,
};
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
