import axios from "axios";
import jwt_decode from "jwt-decode";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  async (config) => {
    let token =
      localStorage.getItem("id_token") || localStorage.getItem("token");

    if (token) {
      const { exp } = jwt_decode(token);
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem("id_token");
        window.location.href = "/admin/signin";
        return Promise.reject("Token expired");
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    // console.log(config)
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default axios;
