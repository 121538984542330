import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState:{
       idToken: localStorage.getItem('id_token'),
       loggedInUser: {},
       changePassword: false,
       error:null,
       redirectToLogin: false,
       firstLogin: false,
    },
    reducers:{
        login: (state, action) => { 
        },
        getLoggedInUser:(state,action) => {

        },

        getLoggedInUserSuccess:(state,action) => {
          state.loggedInUser = action.payload
        },

        updatePass: (state, action) => { 
       
        },
        loginSuccess: (state, action) => {
            state.idToken = action.payload.token
            state.error = null
        },
        loginFailure: (state, action) => {
            state.idToken = null;
            state.error = action.payload; // Set the error message in the state
          },
        logout: (state) => {
           
          },
        logoutSuccess: (state) => {
            state.idToken = null;
            state.error = null;
          },
          navigateToChangePassword: (state , action) => {
            state.changePassword=true;
            state.redirectToLogin=false;
            state.error = null;
          },
          passChangeSuccess: (state , action) => {
            state.redirectToLogin=true;
            state.changePassword=false;
            state.firstLogin = true;
            state.error = null;
          }
          
    }
});

export const {login,loginSuccess,logout,loginFailure,logoutSuccess,navigateToChangePassword,updatePass,passChangeSuccess,getLoggedInUser,getLoggedInUserSuccess} = authSlice.actions;
export default authSlice.reducer;