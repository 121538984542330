import React from 'react';
import { Form, Input, Button} from 'antd';
import {createUser} from "../../redux/users/userState";
import { connect } from "react-redux";
import {getLoggedInUser} from"../../redux/auth/authState";


const EditProfileForm = ({ loggedInUser,createUser}) => {
 
const [form] = Form.useForm();


const onFinish = (values) => {
  values.roleId = loggedInUser.RoleId
  values.id = loggedInUser.id
  console.log(loggedInUser)
  createUser({user:values, actionName: 'update' })
};

 
  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        firstName: loggedInUser.firstName,
        lastName: loggedInUser.lastName,
        phoneNumber: loggedInUser.phoneNumber,
        email: loggedInUser.email,
        
      }}
    >
      <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Password" name="password">
        <Input/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
        Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

const mapDispatchToProps = {
  createUser: createUser,
  getLoggedInUser: getLoggedInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);

