import styled from 'styled-components';
const Fieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
 .ant-table-container {
    overflow-x: scroll;
  }
  .ant-table-thead th {
    position: sticky;
    top: 0;
    background-color: #fff; /* Optional: To give it a white background */
    z-index: 1; /* Optional: To layer it above the table content */
  }
  
`;

const Label = styled.label`
  font-size: 13px;
  color: black;
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  margin: 0 0 8px;
`;
export {
    Fieldset,
    Label,
  };
  