import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleModalUser,
  update,
  createUser,
  getUsers,
} from "../../redux/users/userState";
import Box from "../../components/utility/box";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import {
  Table,
  Button,
  Breadcrumb,
  Space,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import Header from "../../components/utility/header";
import ComponentTitle from "../../components/utility/componentTitle";
import {
  DashboardOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import clone from "clone";
import axios from "../../api";
import { Fieldset } from "./users.style";
import { notification } from "antd";
import { getLoggedInUser } from "../../redux/auth/authState";

export const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchedColumn: "",
      roles: [],
      deleteModalVisible: false,
      userToDelete: null,
    };
    this.searchInput = React.createRef();
  }

  async componentDidMount() {
    try {
      await this.props.getUsers();
      const rolesResponse = await axios.get("usermanagement/roles");
      this.setState({ roles: rolesResponse.data });
    } catch (error) {
      console.error(error);
    }
  }

  handleModal = (user = null) => {
    this.props.toggleModalUser(user);
  };
  showDeleteModal = (user) => {
    this.setState({ deleteModalVisible: true, userToDelete: user });
  };

  hideDeleteModal = () => {
    this.setState({ deleteModalVisible: false, userToDelete: null });
  };

  handleDelete = () => {
    // Call your delete action here
    this.props.createUser({
      user: this.state.userToDelete,
      actionName: "delete",
    });
    this.hideDeleteModal();
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  onRecordChange = (key, event) => {
    let { user } = clone(this.props);
    if (key) user[key] = event.target.value;
    this.props.update(user);
  };
  onSelectChange = (key, value) => {
    let { user } = clone(this.props);
    if (key) user[key] = value;
    this.props.update(user);
  };
  handleRecord = (actionName, user) => {
    const token = localStorage.getItem("id_token");

    try {
      if (user.key && actionName !== "delete") actionName = "update";
      this.props.createUser({ user, actionName });
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { user, users, loggedInUser } = this.props;
    const dataSource = [];
    Object.keys(users).map((user, index) => {
      return dataSource.push({
        id: users[user].id,
        email: users[user].email,
        firstName: users[user].firstName,
        lastName: users[user].lastName,
        phoneNumber: users[user].phoneNumber,
        role: users[user].Role ? users[user].Role.name : null, // Ensure user role is accessible
        roleId: users[user].Role ? users[user].Role.RoleId : null, // Ensure roleId is accessible
        // ...users[user],
        key: user,
      });
    });
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 20,
      },
    };

    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };

    const columns = [
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
        ...this.getColumnSearchProps("firstName"),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: "Phone No.",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
    ];
    const hasEditPermission = loggedInUser?.permissions?.includes("editUser");
    const hasDeletePermission =
      loggedInUser?.permissions?.includes("deleteUser");

    // Conditionally add the "Action" column based on permissions
    if (hasEditPermission || hasDeletePermission) {
      columns.push({
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            {loggedInUser?.permissions?.includes("editUser") ? (
              <a onClick={this.handleModal.bind(this, record)} href="#">
                <EditOutlined />
              </a>
            ) : null}
            {loggedInUser?.permissions?.includes("deleteUser") ? (
              <a onClick={() => this.showDeleteModal(record)} href="#">
                <DeleteOutlined style={{ color: "red" }} />
              </a>
            ) : null}
          </Space>
        ),
      });
    }

    return (
      <LayoutContentWrapper>
        <Breadcrumb
          items={[
            {
              href: "/ethiomaps",
              title: <DashboardOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <UserOutlined />
                  <span>User Management</span>
                </>
              ),
            },
            {
              title: "Users",
            },
          ]}
        />
        <Box title="" subtitle="" style={{ marginTop: "3.5%" }}>
          <Header>
            <ComponentTitle>Users</ComponentTitle>
            {loggedInUser?.permissions?.includes("createUser") ? (
              <Button
                className="addButton"
                onClick={this.handleModal.bind(this, null)}
              >
                Add User
              </Button>
            ) : null}
          </Header>
          <Modal
            title="Confirm Delete"
            open={this.state.deleteModalVisible}
            onOk={this.handleDelete}
            onCancel={this.hideDeleteModal}
            okText="Yes"
            cancelText="No"
          >
            Are you sure you want to delete this user?
          </Modal>

          <Modal
            open={this.props.modalUser}
            title={this.props.user.key ? "Update User" : "Add New User"}
            okText={this.props.user.key ? "Update User" : "Add User"}
            onOk={this.handleRecord.bind(this, "insert", user)}
            onCancel={this.handleModal.bind(this, null)}
          >
            <Form
              {...layout}
              name="nest-messages"
              style={{
                maxWidth: 600,
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="firstName"
                    placeholder="Enter First Name"
                    value={user.firstName}
                    onChange={this.onRecordChange.bind(this, "firstName")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="lastName"
                    placeholder="Enter Last Name"
                    value={user.lastName}
                    onChange={this.onRecordChange.bind(this, "lastName")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Not a valid email!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="email"
                    type="email"
                    placeholder="Enter Last Name"
                    value={user.email}
                    onChange={this.onRecordChange.bind(this, "email")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter the phone number!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="phoneNumber"
                    placeholder="Enter Phone No"
                    value={user.phoneNumber}
                    onChange={this.onRecordChange.bind(this, "phoneNumber")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="password"
                    placeholder="Enter password"
                    value={user.password}
                    onChange={this.onRecordChange.bind(this, "password")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="Role"
                name="roleId"
                rules={[
                  {
                    required: true,
                    message: "Please select a role!",
                  },
                ]}
              >
                <Fieldset>
                  <Select
                    onChange={this.onSelectChange.bind(this, "roleId")}
                    value={user.roleId}
                  >
                    {this.state.roles.map((role, i) => {
                      return (
                        <Select.Option key={role.id} value={role.id}>
                          {role.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Fieldset>
              </Form.Item>
            </Form>
          </Modal>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
            }}
            scroll={{ x: "max-content" }}
          />
        </Box>
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.user,
    loggedInUser: state.auth.loggedInUser,
    isLoggedIn: state.auth.idToken !== null ? true : false,
  };
}

const mapDispatchToProps = {
  toggleModalUser,
  update,
  createUser,
  getUsers,
  getLoggedInUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
