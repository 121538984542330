import React from 'react';
import { ComponentTitleWrapper } from './header.style';

const ComponentTitle = (props) => (
  <ComponentTitleWrapper
    className={`${props.className}`}
    style={props.style}
  >
    {props.children}
  </ComponentTitleWrapper>
);

export default ComponentTitle;
