import React, { Component } from 'react';
import { AppBg } from './signin.style';
import { Form, Input, Typography, Button, notification , Divider } from "antd";
import { login , loginFailure} from "../../redux/auth/authState";
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';

const openNotificationWithIcon = (type) => {
  notification.destroy();
  notification[type]({
    message: "Logged in Successfully",
    description:
      "Welcome!",
  });
};

const openErrorNotificationWithIcon = (type) => {
  notification.destroy();
  notification[type]({
    message: "Error",
    description: "Incorrect Username or Password",
  });
};
class Signin extends Component {
  state = {
    redirectToReferrer: false,
    showSuccessNotification: false,
    showErrorNotification: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isLoggedIn !== prevState.isLoggedIn && nextProps.isLoggedIn) {
      openNotificationWithIcon("info")
      return {
        redirectToReferrer: true,
        showSuccessNotification: true,
      };
      
    } 
    else if (
      nextProps.isLoggedIn !== prevState.isLoggedIn &&
      !nextProps.isLoggedIn &&
      nextProps.error && !nextProps.firstLogin // Check if there is an error in the props
    ) {
      openErrorNotificationWithIcon("error");
      return {
        showErrorNotification: true,
      };
    }
    return null;
  }


  handleLogin = async (values) => {
    try {
      // console.log(values);
      await this.props.login(values);
    } catch (error) {
      console.log(error)
      openErrorNotificationWithIcon("error");
    }
  };

  render() {
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      return <Navigate to="/ethiomaps" />;
    }
    else if (this.props.changePassword){
      return <Navigate to="/changepassword" />;
    }
    return (
      <AppBg>
        <Form className='loginForm' onFinish={this.handleLogin}>
          <Typography.Title>Ethio<span>Maps</span></Typography.Title>
          <Form.Item
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email"
              }
            ]}
            label='Email'
            name={'email'}
          >
            <Input placeholder='Enter your email' />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter your password"
              }
            ]}
            label='Password'
            name={'password'}
          >
            <Input.Password placeholder='Enter your password' />
          </Form.Item>
          <Button type='primary' htmlType='submit' block>Login</Button>
          <Divider className='Divider' style={{ borderColor: "black" }}>Welcome!</Divider>
        </Form>
      </AppBg>
    );
          
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.idToken !== null ? true : false,
    error: state.auth.error, 
    changePassword: state.auth.changePassword,
    firstLogin: state.auth.firstLogin
  };
}

const mapDispatchToProps = {
  login,
  loginFailure
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);