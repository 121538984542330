import { createSlice } from "@reduxjs/toolkit";

export const serviceSlice = createSlice({
    name: 'service',
    initialState:{
        isLoading: false,
        modalservice: false,
        services: {},
        errorMessage: false,
        service:{
            key:null,
        }
    },
    reducers:{
        toggleModalService: (state, action) => {
            state.modalservice =  !state.modalservice;
            state.service= action.payload ? (action.payload):({});
        },
        update: (state, action) => {
            state.service = action.payload
        },
        getServices: (state, action) => {
            state.isLoading = true;
            state.errorMessage = false;
            state.modalservice =false;
        },
        getServicesSuccess: (state, action) => {
            state.isLoading = false;
            state.errorMessage = false;
            state.services=action.payload;
        },
        getServicesError: (state, action) => {
            state.isLoading = false;
            state.errorMessage = 'There is a Loading problem';
        },
       
        deleteService: (state, action) => {
        },
        createService: (state, action) => {
        },
    }
});

export const {toggleModalService ,createService,update,deleteService,getServices,getServicesError,getServicesSuccess} = serviceSlice.actions;
export default serviceSlice.reducer;
