import styled from 'styled-components';

export const TopbarWrapper = styled.div`
  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: #DADAE6 !important;
    
    padding: '0 265px 0 31px' ;
    z-index: 1000;

    @media only screen and (max-width: 767px) {
      padding: '0px 260px 0px 15px !important';
    }

    &.collapsed {
      padding: '0 109px 0 31px';
      @media only screen and (max-width: 767px) {
        padding: '0px 15px !important';
      }
    }

    .isoLeft {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        margin: '0 0 0 20px';
      }

      .triggerBtn {
        width: 24px;
        height: 100%;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        position: relative;
        cursor: pointer;
        font-size: 20px;
        

        &:before {
          font-family: 'Ionicons';
          font-size: 26px;
          color: inherit;
          line-height: 0;
          position: absolute;
        }
      }
    }

    .isoRight {
      display: flex;
      align-items: center;

      li {
        margin-left: '35px';
        margin-right:'0';
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: '25px';
          margin-right: '0';
        }

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 24px;
          color: pink;
          line-height: 1;
        }

        .isoIconWrapper {
          position: relative;
          line-height: normal;

          span {
            font-size: 12px;
            color: #fff;
            background-color: pink;
            width: 20px;
            height: 20px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -8px;
            left: 'inherit';
            right: '10px';
          }
        }

        &.isoMail {
          .isoIconWrapper {
            span {
              background-color: pink;
            }
          }
        }

        &.isoNotify {
          .isoIconWrapper {
            span {
              background-color: pink;
            }
          }
        }

        &.isoMsg {
          .isoIconWrapper {
            span {
              background-color:pink;
            }
          }
        }

        &.isoCart {
          .isoIconWrapper {
            span {
              background-color: pink;
            }
          }
        }

        &.isoUser {
          .isoImgWrapper {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: pink;

            img {
              height: 100%;
              object-fit: cover;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: pink;
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
            }
          
        }
      
    }
  

  }
`;