import styled from "styled-components";

export const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    background: white;
    width: 280px;
    height: 100%;
    flex: 0 0 280px;
    .ant-layout-sider-trigger{
        display:none;
    }

    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 115%;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      background: transparent;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;

      h3 {
        a {
          font-size: 21px;
          font-weight: 400;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color:  #141466;
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
        padding-bottom: 35px;
        background: transparent;
        width:75%;
        font-weight: 600;
        border: none;
        margin: auto;

        a {
            text-decoration: none;
            font-weight: 600;
        }
        
        li{
            color: black;
        }
       
      
  }
`;
