import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "../../api";
import { getClientsSuccess, getClientsError, getClients } from "./clientState";
import { openNotificationWithIconClient } from "../../containers/client/client";

function* workGetClients() {
  try {
    const response = yield call(axios.get, "usermanagement/clients");
    const data = [];
    for (let index = 0; index < response.data.length; index++) {
      data.push({
        id: response.data[index].id,
        email: response.data[index].email,
        name: response.data[index].name,
        apiKey: response.data[index].apiKey,
        paymentStatus: response.data[index].paymentStatus,
        services: response.data[index].Services,
      });
    }
    yield put(getClientsSuccess(data));
  } catch (error) {
    yield put(getClientsError(error));
  }
}
function* workCreateClients(action) {
  const { client, actionName } = action.payload;
  // Validate the form fields
  if (
    !client ||
    !client.name ||
    !client.email ||
    client.services.length === 0
  ) {
    // Display an error notification for missing fields
    openNotificationWithIconClient(
      "error",
      "Please fill in all required fields."
    );
    return;
  }
  let userWithIdsOnly;
  if (client.hasOwnProperty("services")) {
    if (client.services.length > 0) {
      userWithIdsOnly = {
        ...client,
        serviceId: client.services.map((service) => service.id),
      };
    }
  }

  try {
    switch (actionName) {
      case "delete":
        yield call(() => axios.delete(`usermanagement/clients/${client.id}`));
        // Show success notification for delete
        openNotificationWithIconClient(
          "success",
          "Client deleted successfully"
        );
        break;
      case "update":
        const userDataEdit = {
          name: client.name,
          email: client.email,
          paymentStatus: client.paymentStatus,
          serviceIds: userWithIdsOnly.serviceId,
        };
        yield call(() =>
          axios.put(`usermanagement/clients/${client.id}`, userDataEdit)
        );
        // Show success notification for update
        openNotificationWithIconClient(
          "success",
          "Client updated successfully"
        );
        break;

      case "insert":
        const userData = {
          name: client.name,
          email: client.email,
          paymentStatus: client.paymentStatus,
          serviceIds: userWithIdsOnly.serviceId,
        };
        yield call(() => axios.post("usermanagement/clients", userData));
        // Show success notification for insert
        openNotificationWithIconClient("success", "Client added successfully");
        break;

      default:
    }
    yield put(getClients());
  } catch (error) {
    // Display error notification
    openNotificationWithIconClient(
      "error",
      error.response ? error.response.data.error : "Error occurred"
    );
    console.log(error);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery("client/getClients", workGetClients),
    takeEvery("client/createClient", workCreateClients),
  ]);
}
