import { DashboardOutlined, UserOutlined } from "@ant-design/icons";
import { IoMapOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const options = [
  {
    key: "",
    label: <Link to={""}>Dashboard</Link>,
    icon: <DashboardOutlined />,
  },
  {
    key: "User_Management",
    label: "User Management",
    icon: <UserOutlined />,
    children: [
      {
        key: "getUsers",
        label: <Link to={"users"}>Users</Link>,
      },
      {
        key: "getRoles",
        label: <Link to={"roles"}>Roles</Link>,
      },
      {
        key: "getPermissions",
        label: <Link to={"permissions"}>Permission</Link>,
      },
      {
        key: "getClients",
        label: <Link to={"clients"}>Clients</Link>,
      },
      {
        key: "getServices",
        label: <Link to={"services"}>Services</Link>,
      },
    ],
  },
  {
    key: "Map",
    label: <Link to={""}>Map</Link>,
    icon: <IoMapOutline />,
    children: [
      {
        key: "getPlaces",
        label: <Link to={"places"}>Places</Link>,
      },
    ],
  },
];
export default options;
