import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "../../api";
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  navigateToChangePassword,
  passChangeSuccess,
  getLoggedInUserSuccess,
} from "./authState";
import jwt_decode from "jwt-decode";

function* workLogin(action) {
  const { email, password } = action.payload;
  localStorage.removeItem("token");
  try {
    const userData = {
      email: email,
      password: password,
    };
    const response = yield call(() =>
      axios.post("usermanagement/login", userData)
    );
    if (response.data.message === "Password change required") {
      yield localStorage.setItem("token", response.data.token);
      yield put(navigateToChangePassword());
    } else {
      yield put(loginSuccess({ token: response.data.token }));
      localStorage.setItem("id_token", response.data.token);
    }
  } catch (error) {
    console.log(error);
    yield put(loginFailure("Incorrect Username or Password"));
  }
}

function* workUpdatePass(action) {
  try {
    const id = action.payload.id;
    delete action.payload.id;
    const response = yield call(() =>
      axios.post(`usermanagement/users/change-password/${id}`, action.payload)
    );
    localStorage.removeItem("token");
    yield put(passChangeSuccess());
  } catch (error) {
    console.log(error);
    yield put(loginFailure("Password Change Failed"));
  }
}

function performLogout() {
  localStorage.removeItem("id_token");
  return Promise.resolve();
}

function* workLogout() {
  try {
    yield call(performLogout);
    yield put(logoutSuccess());
  } catch (error) {
    console.log(error);
  }
}

function* workGetLoggedInUser(action) {
  try {
    const token = action.payload.idToken;
    const response = yield call(
      axios.get,
      `usermanagement/user/${jwt_decode(token).id}`
    );
    const user = response.data;
    yield put(getLoggedInUserSuccess(user));
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery("auth/login", workLogin),
    takeEvery("auth/logout", workLogout),
    takeEvery("auth/updatePass", workUpdatePass),
    takeEvery("auth/getLoggedInUser", workGetLoggedInUser),
  ]);
}
