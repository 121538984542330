import React, { useState, useEffect, useRef } from "react";
import { logout } from "../../redux/auth/authState";
import { connect } from "react-redux";
import EditProfileForm from "../Page/editprofileform";
import { Modal } from "antd";
import { CiEdit } from "react-icons/ci";
import { IoIosLogOut } from "react-icons/io";

import "./userDropdown.css";

function UserDropdown(props) {
  const [open, setOpen] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const handleEdit = () => {
    setShowEditForm(true);
  };

  const handleEditSubmit = (editedUserData) => {
    setShowEditForm(false); // Close the edit form after submission
  };
  return (
    <div className="App">
      <div className="menu-container" ref={menuRef}>
        <div
          className="menu-trigger"
          onClick={() => {
            console.log("clicked");
            setOpen(!open);
          }}
        >
          <img src={props.userImg} alt="user" />
        </div>

        <div
          className={` ${
            open ? "block" : "hidden"
          } absolute top-20 right-5 bg-white w-56 min-w-[300px] flex flex-col rounded-md shadow-md transition-all duration-300 ease-[cubic-bezier(0.215,0.61,0.355,1)] z-10`}
        >
          {props.loggedInUser && Object.keys(props.loggedInUser).length > 0 ? (
            <>
              <h3 className="px-4 py-2 border-b border-gray-200 text-lg font-semibold">
                {`${props.loggedInUser.firstName || ""} ${
                  props.loggedInUser.lastName || ""
                }`}
                <br />
                <span className="text-sm text-gray-600">
                  {props.loggedInUser.Role
                    ? props.loggedInUser.Role.name || ""
                    : ""}
                </span>
              </h3>
              <ul className="py-0 px-5">
                <li className="flex py-0">
                  <CiEdit className="pr-4 text-4xl my-auto" />
                  <a onClick={handleEdit}>Edit Profile</a>
                </li>
                <li className="flex py-0">
                  <IoIosLogOut className="pr-4 text-4xl my-auto" />
                  <a onClick={() => props.logout()}>Logout</a>
                </li>
              </ul>
            </>
          ) : (
            <h3 className="px-4 py-2 text-center text-gray-500">Loading...</h3>
          )}
        </div>
      </div>
      {showEditForm && (
        <Modal
          title="Edit Profile"
          open={showEditForm}
          onCancel={() => setShowEditForm(false)}
          footer={null}
        >
          <EditProfileForm
            loggedInUser={props.loggedInUser}
            onSubmit={handleEditSubmit}
          />
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

const mapDispatchToProps = {
  logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
