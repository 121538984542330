import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    modalUser: false,
    errorMessage: false,
    user: {
      key: null,
    },
    users: {},
  },
  reducers: {
    toggleModalUser: (state, action) => {
      state.modalUser = !state.modalUser;
      state.user = action.payload ? action.payload : {};
    },
    update: (state, action) => {
      state.user = action.payload;
    },
    getUsers: (state, action) => {
      state.isLoading = true;
      state.errorMessage = false;
      state.modalUser = false;
    },
    getUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.errorMessage = false;
      state.users = action.payload;
    },
    getUsersError: (state, action) => {
      state.isLoading = false;
      state.errorMessage = "There is a Loading problem";
    },
    createUser: (state, action) => {},
  },
});

export const {
  toggleModalUser,
  update,
  createUser,
  getUsers,
  getUsersError,
  getUsersSuccess,
} = userSlice.actions;
export default userSlice.reducer;
