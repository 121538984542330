import { all } from "redux-saga/effects";
import users from "./users/saga";
import auth from "./auth/saga";
import role from "./roles/saga";
import permission from "./permissions/saga";
import client from "./client/saga";
import service from "./services/saga";
import places from "./places/saga";

export default function* rootSaga() {
  yield all([
    users(),
    auth(),
    role(),
    permission(),
    client(),
    service(),
    places(),
  ]);
}
