import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import {
  getServicesSuccess,
  getServicesError,
  getServices,
} from "./servicesState";
import { openNotificationWithIcons } from "../../containers/services/zservices";

function* workGetServices() {
  try {
    const response = yield call(axios.get, "usermanagement/services");
    const data = [];

    for (let index = 0; index < response.data.length; index++) {
      data.push({
        id: response.data[index].id,
        name: response.data[index].name, // Push the extracted names to the "permissions" property
      });
    }
    yield put(getServicesSuccess(data));
  } catch (error) {
    yield put(getServicesError(error));
  }
}
function* workCreateService(action) {
  const { service, actionName } = action.payload;

  try {
    switch (actionName) {
      case "delete":
        yield call(() => axios.delete(`usermanagement/services/${service.id}`));
        openNotificationWithIcons("success", "Service deleted successfully");
        break;
      case "update":
        const serviceDataEdit = {
          name: service.name,
        };
        yield call(() =>
          axios.put(`usermanagement/services/${service.id}`, serviceDataEdit)
        );
        openNotificationWithIcons("success", "Service updated successfully");
        break;

      default:
        const serviceData = {
          name: service.name,
        };
        yield call(() => axios.post("usermanagement/services", serviceData));
        openNotificationWithIcons("success", "Service added successfully");
        break;
    }
    yield put(getServices());
  } catch (error) {
    openNotificationWithIcons(
      "error",
      error.response ? error.response.data.error : "Error occurred"
    );
  }
}
function* workDeleteService(action) {
  yield call(() =>
    axios.delete(`usermanagement/services/${action.payload.id}`)
  );
  yield put(getServices());
}

export default function* rootSaga() {
  yield all([
    takeEvery("service/getServices", workGetServices),
    takeEvery("service/createService", workCreateService),
    takeEvery("service/deleteService", workDeleteService),
  ]);
}
