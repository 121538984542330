import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import { getUsersSuccess, getUsersError, getUsers } from "./userState";
import { openNotificationWithIcon } from "../../containers/Users/users";

function* workGetUsers() {
  try {
    const response = yield call(axios.get, "usermanagement/users");
    // console.log("Response data:", response.data); // Check response data
    // const data = Object.values(response.data).map((user) => {
    //   return {
    //     id: user.id,
    //     email: user.email,
    //     firstName: user.firstName,
    //     lastName: user.lastName,
    //     phoneNumber: user.phoneNumber,
    //     role: user.Role.name, // Ensure user role is accessible
    //     roleId: user.RoleId, // Ensure roleId is accessible
    //   };
    // });
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    yield put(getUsersError(error));
  }
}
function* workCreateUsers(action) {
  const { user, actionName } = action.payload;
  console.log(user);
  try {
    switch (actionName) {
      case "delete":
        yield call(() => axios.delete(`usermanagement/users/${user.id}`));
        // Show success notification for delete
        openNotificationWithIcon("success", "User deleted successfully");
        break;
      case "update":
        const userDataEdit = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          password: user.password,
          RoleId: user.roleId,
        };
        yield call(() =>
          axios.put(`usermanagement/users/${user.id}`, userDataEdit)
        );
        // Show success notification for update
        openNotificationWithIcon("success", "User updated successfully");
        break;

      case "insert":
        const userData = {
          firstName: user.firstName,
          email: user.email,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          password: user.password,
          RoleId: user.roleId,
        };
        yield call(() => axios.post("usermanagement/users", userData));
        // Show success notification for insert
        openNotificationWithIcon("success", "User added successfully");
        break;
    }
    yield put(getUsers());
  } catch (error) {
    // Display error notification
    openNotificationWithIcon(
      "error",
      error.response ? error.response.data.error : "Error occurred"
    );
    // Dispatch an error action
    console.log(error);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery("user/getUsers", workGetUsers),
    takeEvery("user/createUser", workCreateUsers),
  ]);
}
