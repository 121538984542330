import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleModalRole,
  update,
  createRole,
  getRoles,
} from "../../redux/roles/roleState";
import Box from "../../components/utility/box";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import {
  Table,
  Button,
  Breadcrumb,
  Space,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import Header from "../../components/utility/header";
import ComponentTitle from "../../components/utility/componentTitle";
import {
  DashboardOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Fieldset } from "../Roles/roles.style";
import clone from "clone";
import axios from "../../api";
import { notification } from "antd";
import { getLoggedInUser } from "../../redux/auth/authState";

export const openNotificationWithIconRole = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};
function convertBackendNameToFrontend(name) {
  // Split the name by camelCase or use any other logic to format it
  const words = name.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them with a space
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchedColumn: "",
      permissions: [],
      deleteModalVisible: false,
      roleToDelete: null,
    };
    this.searchInput = React.createRef();
  }
  async componentDidMount() {
    const token = localStorage.getItem("id_token");
    // this.props.getLoggedInUser({token});

    try {
      await this.props.getRoles();
      const response = await axios.get(`usermanagement/permissions`);

      const options = response.data.map((permission) => ({
        label: convertBackendNameToFrontend(permission.name),
        value: permission.id,
      }));

      this.setState({ permissions: options });
    } catch (error) {
      console.error(error);
    }
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleModal = (role = null) => {
    this.props.toggleModalRole(role);
  };
  showDeleteModal = (role) => {
    this.setState({ deleteModalVisible: true, roleToDelete: role });
  };

  hideDeleteModal = () => {
    this.setState({ deleteModalVisible: false, roleToDelete: null });
  };

  handleDelete = () => {
    // Call your delete action here
    this.props.createRole({
      role: this.state.roleToDelete,
      actionName: "delete",
    });
    this.hideDeleteModal();
  };

  onRecordChange = (key, event) => {
    let { role } = clone(this.props);
    if (key) role[key] = event.target.value;
    this.props.update(role);
  };

  onSelectChange = (key, selectedIds) => {
    let { role } = clone(this.props);
    if (key === "permissions") {
      // Map the selected IDs to an array of permission objects
      const selectedPermissions = selectedIds.map((id) => {
        const permission = this.state.permissions.find((p) => p.value === id);
        return {
          id,
          name: permission ? permission.label : "",
        };
      });
      role[key] = selectedPermissions;
    } else {
      role[key] = selectedIds;
    }
    this.props.update(role);
  };

  handleRecord = (actionName, role) => {
    const token = localStorage.getItem("id_token");
    // this.props.getLoggedInUser({token});

    try {
      if (role.key && actionName !== "delete") actionName = "update";
      this.props.createRole({ role, actionName });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { role, roles, loggedInUser } = this.props;
    const dataSource = [];
    Object.keys(roles).map((role, index) => {
      return dataSource.push({
        ...roles[role],
        key: role,
      });
    });
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 20,
      },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };

    const columns = [
      {
        title: "Roles",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Permissions",
        dataIndex: "permissions",
        key: "permissions",
        render: (_, record) => {
          const permissionsNames = record.permissions.map(
            (permission) => permission.name
          );
          const permissionsString = permissionsNames.join(", ");
          return permissionsString;
        },
      },
    ];

    // Check if either 'editRole' or 'deleteRole' permissions exist
    const hasEditPermission = loggedInUser?.permissions?.includes("editRole");
    const hasDeletePermission =
      loggedInUser?.permissions?.includes("deleteRole");

    // Conditionally add the "Action" column based on permissions
    if (hasEditPermission || hasDeletePermission) {
      columns.push({
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            {hasEditPermission && (
              <a onClick={this.handleModal.bind(this, record)} href="#">
                <EditOutlined />
              </a>
            )}
            {hasDeletePermission && (
              <a onClick={() => this.showDeleteModal(record)} href="#">
                <DeleteOutlined style={{ color: "red" }} />
              </a>
            )}
          </Space>
        ),
      });
    }

    return (
      <LayoutContentWrapper>
        <Breadcrumb
          items={[
            {
              href: "/ethiomaps",
              title: <DashboardOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <UserOutlined />
                  <span>Role Management</span>
                </>
              ),
            },
            {
              title: "Roles",
            },
          ]}
        />
        <Box title="" subtitle="" style={{ marginTop: "3.5%" }}>
          <Header>
            <ComponentTitle>Roles</ComponentTitle>
            {loggedInUser?.permissions?.includes("createRole") ? (
              <Button
                className="addButton"
                onClick={this.handleModal.bind(this, null)}
              >
                Add role
              </Button>
            ) : null}
          </Header>
          <Modal
            title="Confirm Delete"
            open={this.state.deleteModalVisible}
            onOk={this.handleDelete}
            onCancel={this.hideDeleteModal}
            okText="Yes"
            cancelText="No"
          >
            Are you sure you want to delete this role?
          </Modal>

          <Modal
            open={this.props.modalrole}
            title={this.props.role.key ? "Update Role" : "Add New role"}
            okText={this.props.role.key ? "Update Role" : "Add role"}
            onOk={this.handleRecord.bind(this, "insert", role)}
            onCancel={this.handleModal.bind(this, null)}
          >
            <Form
              {...layout}
              name="nest-messages"
              // onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                label="Role Name"
                name="rolename"
                rules={[
                  {
                    required: true,
                    message: "Please enter the role name!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="name"
                    placeholder="Enter Role Name"
                    value={role.name}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    onChange={this.onRecordChange.bind(this, "name")}
                  />
                </Fieldset>
              </Form.Item>
              <Form.Item
                label="Permissions"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please select permissions!",
                  },
                ]}
              >
                <Fieldset>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                    value={
                      role && role.permissions
                        ? role.permissions.map((permission) => ({
                            label: permission.name,
                            value: permission.id,
                          }))
                        : []
                    }
                    onChange={this.onSelectChange.bind(this, "permissions")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={this.state.permissions}
                  />
                </Fieldset>
              </Form.Item>
            </Form>
          </Modal>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
            }}
            scroll={{ x: "max-content" }}
          />
        </Box>
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.role,
    loggedInUser: state.auth.loggedInUser,
  };
}

const mapDispatchToProps = {
  toggleModalRole,
  update,
  createRole,
  getRoles,
  getLoggedInUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Roles);
