import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "../../api";
import {
  getPlacesSuccess,
  getPlacesError,
  getPlaces,
  setSelectedPlace,
} from "./placesState";
import { openNotificationWithIcon } from "../../containers/Places/places";

function* workGetPlaces() {
  try {
    const response = yield call(axios.get, "places/places");

    yield put(getPlacesSuccess(response.data));
  } catch (error) {
    yield put(getPlacesError(error));
  }
}

function* workDeletePlaces(action) {
  try {
    yield call(() => axios.delete(`Places/${action.payload.id}`));
    // Show success notification for delete
    openNotificationWithIcon("success", "Permission deleted successfully");

    yield put(getPlaces());
  } catch (error) {
    console.error(error);

    // Show error notification
    openNotificationWithIcon("error", "Failed to delete permission");
  }
}

function* workApprovePlace(action) {
  const { selectedPlace, buttonAction } = action.payload;
  console.log("buttonAction", buttonAction);
  try {
    // let selectedPlace = Object.assign({}, action.payload, {
    //   properties: Object.assign({}, action.payload.properties, {
    //     name: "updated Name",
    //   }),
    // });
    let placeToBeApproved = {};
    if (buttonAction === "approved") {
      placeToBeApproved = {
        ...selectedPlace,
        status: "Approved", // Additional properties if needed
      };
    } else {
      placeToBeApproved = {
        ...selectedPlace,
        status: "Rejected", // Additional properties if needed
      };
    }
    console.log(placeToBeApproved);
    //`${process.env.REACT_APP_PLACES_URL}places/${action.payload}`
    const response = yield call(() =>
      axios.put(
        `https://apigateway.ethiomaps.com/places/places/${selectedPlace.id}`,
        placeToBeApproved,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    );
    yield put(setSelectedPlace(placeToBeApproved));
    if (buttonAction === "Approved") {
      openNotificationWithIcon("success", "Place approved successfully!");
    } else {
      openNotificationWithIcon("success", "Place rejected successfully!");
    }
  } catch (error) {
    console.log(error);
    openNotificationWithIcon("error", "Error approving place");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery("place/getPlaces", workGetPlaces),
    takeEvery("place/deletePlace", workDeletePlaces),
    takeEvery("place/approvePlace", workApprovePlace),
  ]);
}
