import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "../../api";
import {
  getPermissionsSuccess,
  getPermissionsError,
  getPermissions,
} from "./permissionState";
import { openNotificationWithIcon } from "../../containers/Permissions/permission";

function convertBackendNameToFrontend(name) {
  const words = name.split(/(?=[A-Z])/);
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function* workGetPermissions() {
  try {
    const response = yield call(axios.get, "usermanagement/permissions");

    const data = [];

    for (let index = 0; index < response.data.length; index++) {
      const frontendName = convertBackendNameToFrontend(
        response.data[index].name
      );

      data.push({
        id: response.data[index].id,
        name: frontendName,
        roles: response.data[index].Roles,
      });
    }

    yield put(getPermissionsSuccess(data));
  } catch (error) {
    yield put(getPermissionsError(error));
  }
}

function* workDeletePermissions(action) {
  try {
    yield call(() =>
      axios.delete(`usermanagement/permissions/${action.payload.id}`)
    );
    // Show success notification for delete
    openNotificationWithIcon("success", "Permission deleted successfully");

    yield put(getPermissions());
  } catch (error) {
    console.error(error);

    // Show error notification
    openNotificationWithIcon("error", "Failed to delete permission");
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery("permission/getPermissions", workGetPermissions),
    takeEvery("permission/deletePermission", workDeletePermissions),
  ]);
}
