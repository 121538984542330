import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
    name: 'client',
    initialState:{
        isLoading: false,
        modalClient: false,
        errorMessage: false,
        client: {
            key:null,
        },
        clients: {}
    },
    reducers:{
        toggleModalClient: (state, action) => {
            state.modalclient =  !state.modalclient;
            state.client= action.payload ? (action.payload):({});
        },
        update: (state, action) => {
            state.client = action.payload
        },
        getClients: (state, action) => {
            state.isLoading = true;
            state.errorMessage = false;
            state.modalclient = false;
        },
        getClientsSuccess: (state, action) => {
            state.isLoading = false;
            state.errorMessage = false;
            state.clients=action.payload;
        },
        getClientsError: (state, action) => {
            state.isLoading = false;
            state.errorMessage = 'There is a Loading problem';
        },
        createClient: (state, action) => {
            
        },
    }
});

export const {toggleModalClient, update,createClient,getClients,getClientsError,getClientsSuccess} = clientSlice.actions;
export default clientSlice.reducer;
