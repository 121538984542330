import styled from 'styled-components';
import bgImage from '../../image/maps.jpg';
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
 
`;

export const AppBg = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  width:100vw;
  height:100vh;
  background-image:url(${bgImage});
  background-size: cover;

  .loginForm {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    padding: 0px 16px 16px 16px;
    border-radius: 16px;
    height: 60vh;
  }

  h1{
    text-align: center;
    font-size: 35px;
    font-weight: 300;
    margin-bottom:0.8em;
  }
  h1 span{
    font-weight: 400;
  }

.Divider{
  margin-top:60px;
}

`;