import styled from 'styled-components';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;

  .addButton {
    background-color: #2929cc;
    color: #FFFFFA;
    font-weight: 500;
    border-color: none;

    &:hover {
    
        color: white;
      
    }
  }
`;
const ComponentTitleWrapper = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin: 5px 0;
`;
export { HeaderWrapper,ComponentTitleWrapper };
