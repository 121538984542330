import React, { useState, useEffect } from "react";
import Sider from "antd/es/layout/Sider";
import { SidebarWrapper } from "./sidebar.style";
import { Menu } from "antd";
import { connect } from "react-redux";
import { toggleCollapsed } from "../../redux/app/appState";
import Logo from "../../components/utility/logo";
import options from "./options";

const Sidebar = ({ collapsed, loggedInUser }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    updateFilteredOptions();
  }, [loggedInUser]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const updateFilteredOptions = () => {
    const updatedOptions = options
      .map((option) => {
        if (option.key === "User_Management") {
          if (option.children) {
            const filteredChildren = option.children.filter((child) =>
              loggedInUser?.permissions?.includes(child.key)
            );
            return filteredChildren.length > 0
              ? { ...option, children: filteredChildren }
              : null;
          }
          return null;
        }
        // else if (option.key === "Map" && loggedInUser?.roleName !== "Admin") {
        //   return null;
        else {
          return option;
        }
      })
      .filter(Boolean);
    setFilteredOptions(updatedOptions);
  };

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={collapsed}
        width="270px"
        className="isomorphicSidebar"
      >
        <div>
          <Logo collapsed={collapsed} />
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            className="isoDashboardMenu"
            style={{ width: "80%" }}
            items={filteredOptions.map((singleOption) => ({
              key: singleOption.key,
              icon: singleOption.icon,
              children: singleOption.children,
              label: singleOption.label,
              // type: singleOption.type,
            }))}
          />
        </div>
      </Sider>
    </SidebarWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    collapsed: state.app.collapsed,
    loggedInUser: state.auth.loggedInUser,
  };
};

const mapDispatchToProps = {
  toggleCollapsed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
