import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  deletePlace,
  getPlaces,
  setSelectedPlace,
} from "../../redux/places/placesState";
import { getLoggedInUser } from "../../redux/auth/authState";
import Box from "../../components/utility/box";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import { Table, Button, Breadcrumb, Space, Modal, Input, Tag } from "antd";
import Header from "../../components/utility/header";
import ComponentTitle from "../../components/utility/componentTitle";
import {
  DashboardOutlined,
  UserOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { notification } from "antd";
import { TableStyle } from "../Users/users.style";
import { getUsers } from "../../redux/users/userState";
import { useNavigate } from "react-router-dom";
import "./places.css";

export const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};
const Places = ({
  getPlaces,
  places,
  loggedInUser,
  deletePlace,
  setSelectedPlace,
  users,
  getUsers,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [placeToDelete, setPlaceToDelete] = useState(null);
  const searchInput = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPlaces();
    getUsers();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleRecord = (role) => {
    try {
      deletePlace(role);
    } catch (error) {
      console.error(error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getStatusTag = (status) => {
    let color;
    switch (status) {
      case "Approved":
        color = "green";
        break;
      case "Rejected":
        color = "red";
        break;
      case "Pending":
      default:
        color = "gold";
        break;
    }
    return <Tag color={color}>{status.toUpperCase()}</Tag>;
  };
  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
    setPlaceToDelete(null);
  };

  const handleDelete = () => {
    deletePlace(placeToDelete);
    hideDeleteModal();
  };

  const handleDetailClick = (place) => {
    const placeDetail = Object.values(places).find(
      (selectedPlace) => selectedPlace.id === place.id
    );
    setSelectedPlace(placeDetail);
    navigate("/ethiomaps/place_detail");
  };
  const dataSource = [];

  const filteredDataSource = Object.keys(places).reduce((acc, place) => {
    const {
      id,
      properties,
      coordinates,
      userId,
      status,
      createdAt,
      updatedAt,
    } = places[place];

    // Check if 'name' is empty and skip this iteration if it is
    if (!properties || !properties.name) {
      return acc;
    } else if (typeof coordinates === "string") {
      return acc;
    }

    const operator = properties?.addendum?.osm?.operator || "";
    const label = properties.label || "";
    const name = properties.name || "";
    const country = properties.country || "";
    const county = properties.county || "";
    const region = properties.region || "";
    const source = properties.source || "";

    // Format coordinates
    let formattedCoordinates = "";
    if (coordinates && typeof coordinates === "object") {
      formattedCoordinates = `${coordinates[0]}, ${coordinates[1]}`;
    } else if (typeof coordinates === "string") {
      formattedCoordinates = coordinates;
    }

    // Get user's full name if available

    let userName = userId;
    if (users) {
      const user = Object.values(users).find((user) => user.id === userId);
      if (user) {
        userName = `${user.firstName} ${user.lastName}`;
      } else {
        return acc;
      }
    }

    acc.push({
      id: id,
      name: name,
      coordinates: formattedCoordinates,
      label: label,
      country: country,
      region: region,
      county: county,
      operator: operator,
      source: source,
      user: userName,
      status: status,
      createdAt: new Date(createdAt).toLocaleString(),
      updatedAt: new Date(updatedAt).toLocaleString(),
      key: place,
    });
    acc.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return acc;
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Coordinates",
      dataIndex: "coordinates",
      key: "coordinates",
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Approved", value: "Approved" },
        { text: "Rejected", value: "Rejected" },
        { text: "Pending", value: "Pending" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => getStatusTag(status),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <a
            onClick={() => handleDetailClick(record)}
            size="middle"
            className="text-green-600 mr-2"
          >
            Detail
          </a>
          {/* <Button size="middle" className="text-red-600">
            Reject
          </Button> */}
        </div>
      ),
    },
  ];

  const hasDeletePermission =
    loggedInUser?.permissions?.includes("deletePermission");

  // Conditionally add the "Action" column based on permissions
  // if (hasDeletePermission) {
  //   columns.push({
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => (
  //       <Space size="middle">
  //         {hasDeletePermission && (
  //           <a onClick={() => showDeleteModal(record)} href="#">
  //             {" "}
  //             <DeleteOutlined style={{ color: "red" }} />
  //           </a>
  //         )}
  //       </Space>
  //     ),
  //   });
  // }

  return (
    <LayoutContentWrapper>
      <Breadcrumb
        items={[
          {
            href: "/ethiomaps",
            title: <DashboardOutlined />,
          },
          {
            href: "",
            title: (
              <>
                <UserOutlined />
                <span>Places</span>
              </>
            ),
          },
          {
            title: "Places",
          },
        ]}
      />
      <Box title="" subtitle="" style={{ marginTop: "3.5%" }}>
        <Header>
          <ComponentTitle>Places</ComponentTitle>
        </Header>
        <Modal
          title="Confirm Delete"
          open={deleteModalVisible}
          onOk={handleDelete}
          onCancel={hideDeleteModal}
          okText="Yes"
          cancelText="No"
        >
          Are you sure you want to delete this permission?
        </Modal>

        <TableStyle>
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
            }}
            scroll={{ x: "max-content" }}
          />
        </TableStyle>
      </Box>
    </LayoutContentWrapper>
  );
};

function mapStateToProps(state) {
  return {
    ...state.place,
    ...state.user,
    loggedInUser: state.auth.loggedInUser,
  };
}

const mapDispatchToProps = {
  getPlaces,
  deletePlace,
  getLoggedInUser,
  getUsers,
  setSelectedPlace,
};
export default connect(mapStateToProps, mapDispatchToProps)(Places);
