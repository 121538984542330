import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import { getRolesSuccess, getRolesError, getRoles } from "./roleState";
import { openNotificationWithIconRole } from "../../containers/Roles/roles";

function convertBackendNameToFrontend(name) {
  // Split the name by camelCase or use any other logic to format it
  const words = name.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them with a space
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
function* workGetRoles() {
  try {
    const response = yield call(axios.get, "usermanagement/roles");
    const data = [];

    for (let index = 0; index < response.data.length; index++) {
      const permissions = response.data[index].Permissions; // Access the "Permissions" array

      const permissionsNames = permissions.map((permission) => ({
        ...permission,
        name: convertBackendNameToFrontend(permission.name),
      })); // Extract the names from the "Permissions" array
      data.push({
        id: response.data[index].id,
        name: response.data[index].name,
        permissions: permissionsNames, // Push the extracted names to the "permissions" property
      });
    }

    yield put(getRolesSuccess(data));
  } catch (error) {
    yield put(getRolesError(error));
  }
}
function* workCreateRoles(action) {
  const { role, actionName } = action.payload;
  // Validate the form fields

  if (!role || !role.name || role.permissions.length === 0) {
    // Display an error notification for missing fields
    openNotificationWithIconRole(
      "error",
      "Please fill in all required fields."
    );
    return;
  }

  // 'permissions' key exists in the 'role' object
  let roleWithIdsOnly;

  if (role.hasOwnProperty("permissions")) {
    if (role.permissions.length > 0) {
      roleWithIdsOnly = {
        ...role,
        permissions: role.permissions.map((permission) => permission.id),
      };
    }
  }
  try {
    switch (actionName) {
      case "delete":
        yield call(() => axios.delete(`usermanagement/roles/${role.id}`));
        // Show success notification for delete
        openNotificationWithIconRole("success", "Role deleted successfully");
        break;
      case "update":
        const roleDataEdit = {
          name: roleWithIdsOnly.name,
          permissions: roleWithIdsOnly.permissions,
        };
        yield call(() =>
          axios.put(`usermanagement/roles/${role.id}`, roleDataEdit)
        );
        // Show success notification for update
        openNotificationWithIconRole("success", "Role updated successfully");
        break;

      default:
        const roleData = {
          name: roleWithIdsOnly.name,
          permissions: roleWithIdsOnly.permissions,
        };
        yield call(() => axios.post("usermanagement/roles", roleData));
        // Show success notification for insert
        openNotificationWithIconRole("success", "Role added successfully");
        break;
    }
    yield put(getRoles());
  } catch (error) {
    // Display error notification
    openNotificationWithIconRole(
      "error",
      error.response ? error.response.data.error : "Error occurred"
    );
    console.log(error);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery("role/getRoles", workGetRoles),
    takeEvery("role/createRole", workCreateRoles),
  ]);
}
