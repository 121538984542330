import React, { useState,useEffect } from "react";
import Layout from "antd/es/layout/layout";
import { Content } from "antd/es/layout/layout";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from 'react-router-dom';
import {getLoggedInUser} from '../../redux/auth/authState'
import { connect } from "react-redux";

const App = ({getLoggedInUser,idToken}) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getLoggedInUser({idToken});
  }, []);

  return (
    <>
      <Layout style={{ height: windowHeight }}>
        <Layout>
          {/* <Collections /> */}
          <Sidebar />
          <Content style={{ background: '#F7F8FC' }}>
            <Layout>
              <Topbar />
              <Content style={{ background: '#F7F8FC' }}>
                <Outlet />
              </Content>
            </Layout>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = {
  getLoggedInUser: getLoggedInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

