import { combineReducers } from "redux";
import appReducer from "./app/appState";
import userReducer from "./users/userState";
import roleReducer from "./roles/roleState";
import permissionReducer from "./permissions/permissionState";
import clientReducer from "./client/clientState";
import authReducer from "./auth/authState";
import serviceReducer from "./services/servicesState";
import placeReducer from "./places/placesState";
const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  role: roleReducer,
  permission: permissionReducer,
  client: clientReducer,
  service: serviceReducer,
  auth: authReducer,
  place: placeReducer,
  // Add other reducers here
});

export default rootReducer;
