import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export const placeslice = createSlice({
  name: "place",
  initialState: {
    isLoading: false,
    modalplace: false,
    places: {},
    selectedPlace: (() => {
      const storedPlace = localStorage.getItem("selectedPlace");
      if (storedPlace && storedPlace !== "undefined") {
        try {
          return JSON.parse(storedPlace);
        } catch (error) {
          console.error("Error parsing selectedPlace:", error);
          return {};
        }
      } else {
        return {};
      }
    })(),
    errorMessage: false,
    place: {
      key: null,
    },
  },
  reducers: {
    setSelectedPlace: (state, action) => {
      localStorage.setItem("selectedPlace", JSON.stringify(action.payload));
      console.log(action.payload);
      state.selectedPlace = action.payload;
    },
    getPlaces: (state, action) => {
      state.isLoading = true;
      state.errorMessage = false;
    },
    getPlacesSuccess: (state, action) => {
      state.isLoading = false;
      state.errorMessage = false;
      state.places = action.payload;
    },
    getPlacesError: (state, action) => {
      state.isLoading = false;
      state.errorMessage = "There is a Loading problem";
    },
    deletePlace: (state, action) => {},
    approvePlace: (state, action) => {},
  },
});

export const {
  toggleModalPermission,
  deletePlace,
  getPlaces,
  getPlacesError,
  getPlacesSuccess,
  setSelectedPlace,
  approvePlace,
} = placeslice.actions;
export default placeslice.reducer;
