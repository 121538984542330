import { createSlice } from "@reduxjs/toolkit";

export const permissionSlice = createSlice({
    name: 'permission',
    initialState:{
        isLoading: false,
        modalpermission: false,
        permissions: {},
        errorMessage: false,
        permisson:{
            key:null,
        }
    },
    reducers:{
        getPermissions: (state, action) => {
            state.isLoading = true;
            state.errorMessage = false;
        },
        getPermissionsSuccess: (state, action) => {
            state.isLoading = false;
            state.errorMessage = false;
            state.permissions=action.payload;
        },
        getPermissionsError: (state, action) => {
            state.isLoading = false;
            state.errorMessage = 'There is a Loading problem';
        },
        deletePermission: (state, action) => {
            
        },
    }
});

export const {toggleModalPermission , deletePermission,getPermissions,getPermissionsError,getPermissionsSuccess} = permissionSlice.actions;
export default permissionSlice.reducer;
