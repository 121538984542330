import React, { Component } from "react";
import { connect } from "react-redux";
import {
  toggleModalClient,
  update,
  createClient,
  getClients,
} from "../../redux/client/clientState";
import { getLoggedInUser } from "../../redux/auth/authState";
import Box from "../../components/utility/box";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import {
  Table,
  Button,
  Breadcrumb,
  Space,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import Header from "../../components/utility/header";
import ComponentTitle from "../../components/utility/componentTitle";
import {
  DashboardOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Fieldset } from "../client/client.style";
import clone from "clone";
import axios from "../../api";
import { notification } from "antd";

export const openNotificationWithIconClient = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};
class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchedColumn: "",
      services: [],
      deleteModalVisible: false,
      clientToDelete: null,
    };
    this.searchInput = React.createRef();
  }

  async componentDidMount() {
    try {
      await this.props.getClients();
      await axios
        .get("usermanagement/services")
        .then((response) => {
          const options = response.data.map((service) => ({
            label: service.name,
            value: service.id,
          }));

          this.setState({ services: options });
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleModal = (client = null) => {
    this.props.toggleModalClient(client);
  };

  showDeleteModal = (client) => {
    this.setState({ deleteModalVisible: true, clientToDelete: client });
  };

  hideDeleteModal = () => {
    this.setState({ deleteModalVisible: false, clientToDelete: null });
  };

  handleDelete = () => {
    this.props.createClient({
      client: this.state.clientToDelete,
      actionName: "delete",
    });
    this.hideDeleteModal();
  };

  onRecordChange = (key, event) => {
    let { client } = clone(this.props);
    if (key) client[key] = event.target.value;
    this.props.update(client);
  };
  onChange = (key, event) => {
    let { client } = clone(this.props);
    if (key) client[key] = event.target.checked;
    this.props.update(client);
  };
  onSelectChange = (key, selectedIds) => {
    let { client } = clone(this.props);

    if (key === "services") {
      const selectedServices = selectedIds.map((id) => {
        const service = this.state.services.find((p) => p.value === id);
        return {
          id,
          name: service ? service.label : "",
        };
      });
      client[key] = selectedServices;
    } else {
      client[key] = selectedIds;
    }
    this.props.update(client);
  };
  handleRecord = (actionName, client) => {
    try {
      if (client.key && actionName !== "delete") actionName = "update";
      this.props.createClient({ client, actionName });
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const { client, clients, loggedInUser } = this.props;
    const dataSource = [];
    Object.keys(clients).map((client, index) => {
      return dataSource.push({
        ...clients[client],
        key: client,
      });
    });
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 20,
      },
    };

    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Api Key",
        dataIndex: "apiKey",
        key: "apiKey",
      },
      {
        title: "Payment Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (_, record) => {
          if (record.paymentStatus) {
            return "Paid";
          } else {
            return "Not Paid";
          }
        },
      },
      {
        title: "Services",
        dataIndex: "services",
        key: "services",
        render: (_, record) => {
          const servicesNames = record.services.map((service) => service.name); // Extract the names from the "Permissions" array
          const servicesString = servicesNames.join(", ");

          return servicesString;
        },
      },
    ];
    const hasEditPermission = loggedInUser?.permissions?.includes("editClient");
    const hasDeletePermission =
      loggedInUser?.permissions?.includes("deleteClient");

    // Conditionally add the "Action" column based on permissions
    if (hasEditPermission || hasDeletePermission) {
      columns.push({
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            {hasEditPermission && (
              <a onClick={this.handleModal.bind(this, record)} href="#">
                <EditOutlined />
              </a>
            )}
            {hasDeletePermission && (
              <a onClick={() => this.showDeleteModal(record)} href="#">
                <DeleteOutlined style={{ color: "red" }} />
              </a>
            )}
          </Space>
        ),
      });
    }

    return (
      <LayoutContentWrapper>
        <Breadcrumb
          items={[
            {
              href: "/ethiomaps",
              title: <DashboardOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <UserOutlined />
                  <span>Client Management</span>
                </>
              ),
            },
            {
              title: "Clients",
            },
          ]}
        />
        <Box title="" subtitle="" style={{ marginTop: "3.5%" }}>
          <Header>
            <ComponentTitle>Clients</ComponentTitle>
            {loggedInUser?.permissions?.includes("createClient") ? (
              <Button
                className="addButton"
                onClick={this.handleModal.bind(this, null)}
              >
                Add Client
              </Button>
            ) : null}
          </Header>
          <Modal
            title="Confirm Delete"
            open={this.state.deleteModalVisible}
            onOk={this.handleDelete}
            onCancel={this.hideDeleteModal}
            okText="Yes"
            cancelText="No"
          >
            Are you sure you want to delete this client?
          </Modal>

          <Modal
            open={this.props.modalclient}
            title={this.props.client.key ? "Update Client" : "Add New Client"}
            okText={this.props.client.key ? "Update Client" : "Add Client"}
            onOk={this.handleRecord.bind(this, "insert", client)}
            onCancel={this.handleModal.bind(this, null)}
          >
            <Form
              {...layout}
              name="nest-messages"
              // onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="Name"
                    placeholder="Enter name"
                    value={client.name}
                    onChange={this.onRecordChange.bind(this, "name")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter valid email!",
                  },
                ]}
              >
                <Fieldset>
                  <Input
                    label="email"
                    type="email"
                    placeholder="Enter email"
                    value={client.email}
                    onChange={this.onRecordChange.bind(this, "email")}
                  />
                </Fieldset>
              </Form.Item>

              <Form.Item
                label="PaymentStatus"
                name="paymentStatus"
                rules={[
                  {
                    required: true,
                    message: "Please select payment status!",
                  },
                ]}
              >
                <Fieldset>
                  <Select
                    onChange={this.onSelectChange.bind(this, "paymentStatus")}
                    value={client.paymentStatus}
                  >
                    <Select.Option value={true}>Paid</Select.Option>
                    <Select.Option value={false}>Unpaid</Select.Option>
                  </Select>
                </Fieldset>
              </Form.Item>
              <Form.Item
                label="Service"
                name="service"
                rules={[
                  {
                    required: true,
                    message: "Please select service !",
                  },
                ]}
              >
                <Fieldset>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                    value={
                      client && client.services
                        ? client.services.map((service) => ({
                            label: service.name,
                            value: service.id,
                          }))
                        : []
                    }
                    onChange={this.onSelectChange.bind(this, "services")}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={this.state.services}
                  />
                </Fieldset>
              </Form.Item>
            </Form>
          </Modal>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{
              defaultPageSize: 10,
            }}
            scroll={{ x: "max-content" }}
          />
        </Box>
      </LayoutContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.client,
    loggedInUser: state.auth.loggedInUser,
  };
}

const mapDispatchToProps = {
  toggleModalClient,
  update,
  createClient,
  getClients,
  getLoggedInUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
