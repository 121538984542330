import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { FaMapMarkerAlt } from "react-icons/fa";

const MapComponent = ({ markerPosition }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (markerPosition) {
      mapRef.current = L.map("mapForm").setView(markerPosition, 13);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapRef.current);

      const handleMapClick = (e) => {};

      mapRef.current.on("click", handleMapClick);

      return () => {
        mapRef.current.off("click", handleMapClick);
        mapRef.current.remove(); // Ensure the map is properly unmounted
      };
    }
  }, [markerPosition]);

  useEffect(() => {
    if (mapRef.current && markerPosition) {
      mapRef.current.setView(markerPosition, 13); // Center map to markerPosition
      const myIcon = L.icon({
        iconUrl: `data:image/svg+xml;base64,${btoa(
          renderToStaticMarkup(<FaMapMarkerAlt style={{ color: "red" }} />)
        )}`,
        iconSize: [20, 75],
        iconAnchor: [10, 37.5], // Adjusted icon anchor
        popupAnchor: [0, -15],
      });

      L.marker(markerPosition, { icon: myIcon }).addTo(mapRef.current);
    }
  }, [markerPosition]);

  return <div id="mapForm" className="w-full h-full" />;
};

export default MapComponent;
