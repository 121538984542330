import React, { useEffect } from 'react';
import { AppBg } from './signin.style';
import { Form, Input, Typography, Button, notification} from "antd";
import { updatePass , loginFailure} from "../../redux/auth/authState";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const openErrorNotificationWithIcon = (type) => {
  notification.destroy();
  notification[type]({
    message: "Error",
    description: "Password Change Failed",
  });
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ChangePassword = ({ redirectToLogin, updatePass,error }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectToLogin) {
      navigate('/signin');
    }
  }, [redirectToLogin, navigate]);

  const handleLogin = async (values) => {
    if (localStorage.getItem('token') != null) {
      var decoded = jwt_decode(localStorage.getItem('token'));
      console.log(decoded.id);
    }

    try {
      values.id = decoded.id;
      await updatePass(values);
      console.log(error)

    } catch (error) {
      console.log(error);
      openErrorNotificationWithIcon("error");
    }
  };


  return (
    <AppBg>
      <Form {...formItemLayout} className='loginForm' onFinish={handleLogin}>
        <Typography.Title>Ethio<span>Maps</span></Typography.Title>
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: 'Please input your current password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: 'Use strong password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Button type='primary' htmlType='submit' block>Change Password</Button>
        
      </Form>
    </AppBg>
  );
};

const mapStateToProps = (state) => ({
  redirectToLogin: state.auth.redirectToLogin,
  error: state.auth.error,
});

const mapDispatchToProps = {
  updatePass,
  loginFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
