import React,{Component} from "react";
import { connect } from "react-redux";
import {
  deletePermission,
  getPermissions} from "../../redux/permissions/permissionState";
import {getLoggedInUser} from '../../redux/auth/authState'
import Box from '../../components/utility/box';
import LayoutContentWrapper from '../../components/utility/layoutWrapper';
import { Table, Button, Breadcrumb, Space, Modal, Input } from "antd";
import Header from "../../components/utility/header";
import ComponentTitle from "../../components/utility/componentTitle";
import { DashboardOutlined, UserOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { notification } from 'antd';
import {TableStyle} from '../Users/users.style'

export const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === 'success' ? 'Operation Successful' : 'Operation Failed',
    description: description,
  });
};
class Permissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchText: '',
          searchedColumn: '',
          deleteModalVisible: false,
          permissionToDelete: null,
        };
        this.searchInput = React.createRef();
      }
      async componentDidMount() {
        try{
          await this.props.getPermissions();
        } catch (error) {
          console.error(error);
        }
        
      }
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
      handleRecord = (role) => {

      try{
          this.props.deletePermission(role);
      } catch (error) {
        console.error(error);
      }
      };
    
    
      getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={this.searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
             
              <Button type="link" size="small" onClick={close}>
                Close
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.current.select(), 100);
          }
        },
        render: (text) =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });  
    
    handleModal = (permission = null)=>{
        this.props.toggleModalPermission(permission);
    }
    showDeleteModal = (permission) => {
      this.setState({ deleteModalVisible: true, permissionToDelete: permission });
    };
    
    hideDeleteModal = () => {
      this.setState({ deleteModalVisible: false, permissionToDelete: null });
    };
    
    handleDelete = () => {
      this.props.deletePermission(this.state.permissionToDelete);
      this.hideDeleteModal();
    };
    
    
    render() {
      const {permissions,loggedInUser} = this.props;
      const dataSource=[]

      Object.keys(permissions).map((permission, index) => {
        return dataSource.push({
          ...permissions[permission],
          key: permission,
        });
      });
          
          const columns = [
            {
              title: 'Permissions',
              dataIndex: 'name',
              key: 'name',
              ...this.getColumnSearchProps('name'),
            },
            {
              title: 'Roles',
              dataIndex: 'roles',
              key: 'roles',
              render: (_, record) => {
                const rolesNames = record.roles.map(role => role.name); // Extract the names from the "Permissions" array
               const rolesString = rolesNames.join(", ");
                
                return rolesString;
              },
              
            },
          ];

          const hasDeletePermission = loggedInUser?.permissions?.includes('deletePermission');
          
          // Conditionally add the "Action" column based on permissions
          if (hasDeletePermission) {
            columns.push(
              {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  <Space size="middle">
                    {hasDeletePermission && (
                    <a onClick={() => this.showDeleteModal(record)} href="#"> <DeleteOutlined style={{ color: 'red' }} /></a>
                  )}
                  </Space>
                ),
              }
            );
          }
          
        return (
            <LayoutContentWrapper>
                 <Breadcrumb
                        items={[
                        {
                            href: '/ethiomaps',
                            title: <DashboardOutlined />,
                        },
                        {
                            href: '',
                            title: (
                            <>
                                <UserOutlined />
                                <span>Permission Management</span>
                            </>
                            ),
                        },
                        {
                            title: 'Permissions',
                        },
                        ]}
                    />
                <Box title="" subtitle="" style={{marginTop:'3.5%'}}>
                    <Header>
                        <ComponentTitle>Permissions</ComponentTitle>
                    </Header>
                    <Modal
                      title="Confirm Delete"
                      open={this.state.deleteModalVisible}
                      onOk={this.handleDelete}
                      onCancel={this.hideDeleteModal}
                      okText="Yes"
                      cancelText="No"
                    >
                      Are you sure you want to delete this permission?
                    </Modal>
                   
                  <TableStyle>
                    <Table 
                    dataSource={dataSource} 
                    columns={columns}   
                    pagination={{
                    defaultPageSize: 10,
                    }}scroll={{ x: 'max-content' }} />
                    </TableStyle>
                </Box>
            </LayoutContentWrapper>
          );
    }
}

function mapStateToProps(state){
  return{
    ...state.permission,
    loggedInUser:state.auth.loggedInUser,
  };
};

const mapDispatchToProps = {
    getPermissions,
    deletePermission,
    getLoggedInUser,
};
export default connect(mapStateToProps,mapDispatchToProps)(Permissions);