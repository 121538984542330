import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  approvePlace,
  deletePlace,
  getPlaces,
} from "../../redux/places/placesState";
import { getLoggedInUser } from "../../redux/auth/authState";
import Box from "../../components/utility/box";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import { Button, Breadcrumb, Tag } from "antd";
import { DashboardOutlined, UserOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { getUsers } from "../../redux/users/userState";
import MapComponent from "./mapComponent.js";
import "leaflet/dist/leaflet.css";

export const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};
const Place_detail = ({ selectedPlace, users, getUsers, approvePlace }) => {
  useEffect(() => {
    console.log(selectedPlace);
    getUsers();
  }, [selectedPlace]);
  const getUserName = (userId) => {
    let userName = userId;
    if (users) {
      const user = Object.values(users).find((user) => user.id === userId);
      if (user) {
        return (userName = `${user.firstName} ${user.lastName}`);
      }
    }
  };
  const getStatusTag = (status) => {
    let color;
    switch (status) {
      case "Approved":
        color = "green";
        break;
      case "Rejected":
        color = "red";
        break;
      case "Pending":
      default:
        color = "gold";
        break;
    }
    return <Tag color={color}>{status.toUpperCase()}</Tag>;
  };
  const handleApprove = (buttonAction) => {
    console.log(selectedPlace.id);
    approvePlace({ selectedPlace, buttonAction });
  };
  return (
    <LayoutContentWrapper>
      <Breadcrumb
        items={[
          {
            href: "/ethiomaps",
            title: <DashboardOutlined />,
          },
          {
            href: "/admin/ethiomaps/places",
            title: (
              <>
                <UserOutlined />
                <span>Places</span>
              </>
            ),
          },
          {
            title: "Place Detail",
          },
        ]}
      />
      <Box
        title=""
        subtitle=""
        style={{ marginTop: "2%" }}
        className="h-fit !p-0"
      >
        <div className="flex flex-col lg:flex-row h-screen">
          <div className="w-full lg:w-2/3 h-1/3 lg:h-full">
            <MapComponent
              id="mapForm"
              markerPosition={[
                selectedPlace.coordinates[0],
                selectedPlace.coordinates[1],
              ]}
            />
          </div>
          <div className="w-full lg:w-1/3 h-2/3 lg:h-full lg:p-4">
            <div className="shadow-xl rounded-xl border p-4 h-full flex flex-col">
              <div className="flex justify-between mb-4">
                <div className="text-xl font-bold">Details</div>
                <div>
                  <Button
                    size="middle"
                    className="text-green-600 mr-2"
                    onClick={() => handleApprove("approved")}
                  >
                    Approve
                  </Button>
                  <Button
                    size="middle"
                    className="text-red-600"
                    onClick={() => handleApprove("reject")}
                  >
                    Reject
                  </Button>
                </div>
              </div>
              {selectedPlace && (
                <>
                  <div className="mb-2">
                    {getStatusTag(selectedPlace.status)}
                  </div>
                  <div className="overflow-auto">
                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Name:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.name}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Coordinates:</div>
                        <div className="font-semibold">
                          {`${selectedPlace.coordinates[0]}, ${selectedPlace.coordinates[1]}`}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Label:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.label}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Country:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.country}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Region:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.region}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">County:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.county}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Operator:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.addendum.osm.operator}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Source:</div>
                        <div className="font-semibold">
                          {selectedPlace.properties.source}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">User:</div>
                        <div className="font-semibold">
                          {getUserName(selectedPlace.userId)}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Created At:</div>
                        <div className="font-semibold">
                          {new Date(selectedPlace.createdAt).toLocaleString()}
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="text-gray-600">
                        <div className="font-bold text-black">Updated At:</div>
                        <div className="font-semibold">
                          {new Date(selectedPlace.updatedAt).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Box>
    </LayoutContentWrapper>
  );
};

function mapStateToProps(state) {
  return {
    ...state.place,
    ...state.user,
    loggedInUser: state.auth.loggedInUser,
  };
}

const mapDispatchToProps = {
  getPlaces,
  deletePlace,
  getLoggedInUser,
  getUsers,
  approvePlace,
};
export default connect(mapStateToProps, mapDispatchToProps)(Place_detail);
