import React from 'react';
import { HeaderWrapper } from './header.style';

const Header = (props) => (
  <HeaderWrapper
    className={`${props.className}`}
    style={props.style}
  >
    {props.children}
  </HeaderWrapper>
);

export default Header;
